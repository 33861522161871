import { Box } from "@chakra-ui/react";
import React from "react";
import { ContextScope } from "../Context/ContextProvider";
import SingleChat from "./SingleChat";
import { useSelector } from "react-redux";

const ChatBox = ({ user, socket }) => {
  console.log("Chatbox");
  console.log(socket);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const { selectedChat } = ContextScope();
  return (
    <Box
      display={{ base: selectedChat ? "flex" : "none", md: "flex" }}
      alignItems="center"
      flexDir="column"
      p={3}
      width={"100%"}
      height={"100%"}
      borderRadius="lg"
    >
      <SingleChat user={user} socket={socket} />
    </Box>
  );
};

export default ChatBox;
