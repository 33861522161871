import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import ScrollableFeed from "react-scrollable-feed";
import SingleNote from "./SingleNote";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollableNote from "./ScrollAbleNote";
import { ContextScope } from "../../Context/ContextProvider";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useSelector } from "react-redux";

const NotesScroll = () => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const user = useSelector((state) => state.userInfo.userInfo);
  const { selectedChat, setSelectedChat } = ContextScope();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [notes, setNotes] = useState([]);
  const typingHandler = (e) => {
    setNewNote(e.target.value);
  };
  const fetchNotes = async () => {
    if (!selectedChat) return;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios
        .get(`${mainPath}/api/note/${selectedChat._id}/${user._id}`, config)
        .then((res) => {
          setNotes(res.data);
          setLoading(false);
        });
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setLoading(false);
    }
  };
  const sendMessage = async (event) => {
    if (event.key === "Enter" && newNote) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };
        setNewNote("");
        const { data } = await axios.post(
          `${mainPath}/api/note`,
          {
            content: newNote,
            chatId: selectedChat._id,
          },
          config,
        );
        // console.log(data);
        setNotes([...notes, data]);
      } catch (error) {
        toast({
          title: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      }
    }
  };

  const sendIt = async (event) => {
    if (newNote) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };
        setNewNote("");
        const { data } = await axios.post(
          `${mainPath}/api/note`,
          {
            content: newNote,
            chatId: selectedChat._id,
          },
          config,
        );
        // console.log(data);
        setNotes([...notes, data]);
      } catch (error) {
        toast({
          title: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      }
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [selectedChat, fetchAgain]);
  return (
    <>
      {selectedChat ? (
        <>
          <Text
            fontSize={{ base: "28px", md: "30px" }}
            pb={3}
            px={2}
            w="100%"
            fontFamily="Work sans"
            display="flex"
            justifyContent={{ base: "space-between" }}
            alignItems="center"
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              icon={<ArrowBackIcon />}
            />
          </Text>
          <Box
            display="flex"
            flexDir="column"
            justifyContent="flex-end"
            w="100%"
            h="100%"
            border={"1px solid #eee"}
            p={"0px"}
            borderRadius="lg"
            overflowY="hidden"
          >
            <>
              {loading ? (
                <Stack p={"10px"} h={"90%"} verticalAlign={"start"}>
                  <Skeleton borderRadius="4px" height="25px" />
                  <Skeleton borderRadius="4px" height="25px" />
                  <Skeleton borderRadius="4px" height="25px" />
                  <Skeleton borderRadius="4px" height="25px" />
                  <Skeleton borderRadius="4px" height="25px" />
                </Stack>
              ) : (
                <>
                  {/* <div className="messages"> */}
                  <ScrollableNote className="messages" notes={notes} />
                  {/* </div> */}
                </>
              )}
            </>
          </Box>

          {/* <FormControl onKeyDown={sendMessage} isRequired mt={3}> */}
          <FormControl onKeyDown={sendMessage} isRequired mt={3}>
            <InputGroup>
              <Input
                variant="filled"
                bg="#eeeeee"
                placeholder="Wpisz notatkę..."
                onChange={typingHandler}
                value={newNote}
              />
              <InputRightElement onClick={sendIt}>
                <FontAwesomeIcon
                  className="hand"
                  color="#05cdff"
                  icon={faPaperPlane}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </>
      ) : (
        <>
          <Spinner color="red.500" size="xl" />
        </>
      )}
    </>
  );
};

export default NotesScroll;
