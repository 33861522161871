import { Avatar, Tooltip } from "@chakra-ui/react";
import React from "react";
import ScrollableFeed from "react-scrollable-feed";
import { format } from "date-fns";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../config/ChatLogics";
import { useSelector } from "react-redux";

const ScrollableChat = ({ messages }) => {
  const user = useSelector((state) => state.userInfo.userInfo);
  return (
    <ScrollableFeed height={300} forceScroll={false} className="ScrollableFeed">
      {messages &&
        messages.map((m, i) => (
          <div className="messagesWrap" style={{ display: "flex" }} key={m._id}>
            {(isSameSender(messages, m, i, user._id) ||
              isLastMessage(messages, i, user._id)) && (
              <Avatar
                mt="7px"
                mr={1}
                size="sm"
                cursor="pointer"
                name={m.sender.name}
                src={m.sender.pic}
              />
            )}
            <span
              className="small"
              style={{
                // marginLeft: `${
                //     m.sender._id === user._id ? 33 : 0
                // }`,
                marginLeft: isSameSenderMargin(messages, m, i, user._id),
                backgroundColor: `${
                  m.sender._id === user._id ? "#e4eff5" : "#98d1eb"
                }`,
                marginTop: "1px",
                borderRadius: "6px",
                padding: "9px 15px",
                maxWidth: "75%",
              }}
            >
              {m.sender._id !== user._id ? (
                <p className="small gray">
                  {m.sender.name}:{" "}
                  {format(new Date(m.createdAt.toString()), "yyyy-MM-dd")}
                </p>
              ) : (
                <></>
              )}
              {m.content}
            </span>
          </div>
        ))}
    </ScrollableFeed>
  );
};

export default ScrollableChat;
