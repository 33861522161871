import { HStack, Switch } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setShowDone } from "./slices/showDoneSlice";

const ShowDoneComponent = ({ user }) => {
  const dispatch = useDispatch();
  const showDone = useSelector((state) => state.showDone.showDone);
  const switchHandler = () => {
    dispatch(setShowDone(!showDone));
  };
  return (
    <HStack p={4}>
      <p className="small gray">pokaż ukończone </p>
      <Switch
        isChecked={showDone}
        colorScheme="green"
        onChange={switchHandler}
        id="email-alerts"
      />
    </HStack>
  );
};

export default ShowDoneComponent;
