import {
  Button,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const TeamList = ({ team }) => {
  const content = team?.map((t) => (
    <Text marginTop={2}>
      <p>{t.name}</p>
      <p className="small gray">{t.email}</p>
    </Text>
  ));
  return (
    <Popover>
      <PopoverTrigger>
        <Button size={"xs"} color={"blue.300"}>
          <FontAwesomeIcon icon={faUsers} />
        </Button>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>{content}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default TeamList;
