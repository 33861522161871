import { Button, Toast, useToast } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { mainPath } from "../../config/AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { ContextScope } from "../../Context/ContextProvider";
import axios from "axios";
import { setShowDone } from "../slices/showDoneSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";

const DoneTaskComponent = ({ chat, mode }) => {
  // console.log(chat.done);
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bg, setBg] = useState("#f0f4fa");
  const dispatch = useDispatch();
  useEffect(() => {
    // if (chat.done) {
    //   dispatch(setShowDone(false));
    // } else {
    //   dispatch(setShowDone(true));
    // }
    setDone(chat.done);
    if (mode === "sub") {
      setBg("#fafafa");
    }
    if (chat.done) setBg("#9fba3c");
    else {
      if (mode === "sub") {
        setBg("#fafafa");
      } else {
        setBg("#f0f4fa");
      }
    }
  }, [chat]);
  const toast = useToast();
  const user = useSelector((state) => state.userInfo.userInfo);
  const doneChat = async () => {
    console.log("done");
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      setLoading(true);
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/done/${chat._id}`,
          {
            chatId: chat._id,
            done: !done,
          },
          config
        )
        .then((res) => {
          setDone(res.data.done);
          if (mode === "sub") {
            setBg("#fafafa");
          }
          if (res.data.done) setBg("#9fba3c");
          else {
            if (mode === "sub") {
              setBg("#fafafa");
            } else {
              setBg("#f0f4fa");
            }
          }
          dispatch(setSelectedTask(chat._id));
          setLoading(false);
          dispatch(setFetchAgain(!fetchAgain));
        });
    } catch (error) {
      // console.log(error);
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        isLoading={loading}
        bg={"transparent"}
        display={{ base: "flex" }}
        size={mode === "small" ? "xs" : "xs"}
        onClick={doneChat}
      >
        {done === false ? (
          <FontAwesomeIcon color="#0fc3f5" icon={faEllipsis} />
        ) : (
          <FontAwesomeIcon color="#9fba3c" icon={faCheck} />
        )}
      </Button>
    </>
  );
};

export default DoneTaskComponent;
