import {
  Box,
  CircularProgress,
  VStack,
  useToast,
  Button,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Progress,
  Td,
  Tr,
} from "@chakra-ui/react";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { faAnglesUp } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import alarmwhite from "../../images/alarmwhite.png";
import CalendarRow from "./CalendarRow";
import DoneTaskComponent from "../task/DoneTaskComponent";
import orange from "../../images/orange.png";
import red from "../../images/red.png";
import gray from "../../images/gray.png";
import green from "../../images/green.png";
import blue from "../../images/blue.png";
import { format } from "date-fns";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setModalMode } from "../slices/modalModeSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { setIsChatDrawerOpen } from "../isChatDrawerOpenSlice";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { setIsUpdateChatModalOpen } from "../slices/isUpdateChatModalOpenSlice";
import TeamList from "../task/TeamList";
import AcceptTask from "../task/AcceptTask";
import { ContextScope } from "../../Context/ContextProvider";
import { setDrawerMode } from "../slices/drawerModeSlice";

const CalendarRows = ({
  rawChat,
  item,
  setCid,
  baseDate,
  isOpen,
  onClose,
  onOpen,
  title,
  setSelectedChat,
  setTitle,
  setTaskDate,
}) => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const [loading, setLoading] = useState(false);
  const selectedDay = useSelector((state) => state.selectedDay.selectedDay);

  const [chat, setChat] = useState({});
  const toast = useToast();
  const [subtasks, setSubtasks] = useState([]);
  const [subtasksRow, setSubtasksRow] = useState(false);
  const [rowTitle, setRowTitle] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [dateTo, setDateTo] = useState();
  const [alarmDate, setAlarmDate] = useState([]);
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const [myArray, setMyArray] = useState([]);
  const [start, setStart] = useState();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo.userInfo);
  const { task } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { setSelectedPrivateChat } = ContextScope();
  const getSubtasksIds = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/getsubtasksids/${item._id}`,
          {
            chatId: item._id,
            userId: user._id,
          },
          config
        )
        .then((res) => {
          setLoading(false);
          setSubtasks(res.data);
          //   setMyArray(content({ start: res.data.createdAt }));
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (task) {
      getSubtasksIds();
    }
  }, [item]);
  useEffect(() => {
    fetchChatDetails();
  }, [rawChat]);
  const openModal = () => {
    dispatch(setIsUpdateChatModalOpen(true));
    setSelectedChat(item);
  };
  const fetchChatDetails = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          // `${mainPath}/api/chat/sort/${sort}/${asc}`,
          `${mainPath}/api/chat/details`,
          {
            uid: user._id,
            cid: rawChat?._id,
          },
          config
        )
        .then((res) => {
          setChat(res.data[0]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  const handleSubClick = async (target) => {
    setSelectedPrivateChat(null);
    dispatch(setSelectedTask(chat));
    dispatch(setDrawerMode(target));

    const openDrawer = (chat) => {
      // console.log(isChatDrawerOpen);
      setSelectedChat(chat);
      dispatch(setIsChatDrawerOpen(true));
    };
    openDrawer(chat);
    if (target !== "chat") {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        };
        const { data } = await axios.put(
          `${mainPath}/api/chat/read`,
          {
            chatId: chat?._id,
          },
          config
        );

        // dispatch(setFetchAgain(!fetchAgain));
      } catch (error) {}
    }
  };
  return (
    <>
      <tr>
        <td className="calendarbutton">
          <HStack w={"100%"} p={"0px 1px 3px 0px"} borderRadius={"0"}>
            <div
              className="container cointeinermain hand"
              alignContent={"right"}
              alignItems={"right"}
            >
              <Button
                className="nomargin"
                bg={"blue.200"}
                borderColor={"white"}
                size={"xs"}
              >
                <FontAwesomeIcon color="#ffffff" icon={faCaretRight} />
              </Button>
              <div class="overlay overlaymain">
                <HStack className="taskpanel" paddingBottom={"5px"}>
                  {item?.suggestedAdmin?.some((e) => e === user._id) ||
                  item?.suggestedSuper === user._id ||
                  item?.suggestedUsers?.some((e) => e === user._id) ? (
                    <AcceptTask />
                  ) : (
                    <>
                      {" "}
                      <Button
                        marginTop={"5px"}
                        borderRadius={5}
                        w={"100%"}
                        size={"xs"}
                        onClick={() => {
                          dispatch(setModalMode("edit"));
                          openModal();
                        }}
                      >
                        <FontAwesomeIcon color="#aaaaaa" icon={faGear} />
                      </Button>
                      {task ? (
                        <Link
                          to={`../calendar`}
                          target="_parent"
                          rel="noopener noreferer"
                        >
                          <Button
                            borderRadius={5}
                            w={"100%"}
                            className="nomargin"
                            bg={"blue.200"}
                            borderColor={"white"}
                            size={"xs"}
                          >
                            <FontAwesomeIcon
                              color="#ffffff"
                              icon={faCaretLeft}
                            />
                          </Button>
                        </Link>
                      ) : (
                        <Link
                          to={`../calendar/${item._id}`}
                          target="_parent"
                          rel="noopener noreferer"
                        >
                          <Button
                            borderRadius={5}
                            w={"100%"}
                            className="nomargin"
                            bg={"blue.200"}
                            borderColor={"white"}
                            size={"xs"}
                          >
                            <FontAwesomeIcon
                              color="#ffffff"
                              icon={faMagnifyingGlass}
                            />
                          </Button>
                        </Link>
                      )}
                      <div className="donetaskwraper">
                        <DoneTaskComponent chat={item} mode={"small"} />
                      </div>
                      <div className="">
                        <Button
                          // bg={"#d5e9f0"}
                          size={"xs"}
                          color="#05cdff"
                          onClick={() => handleSubClick("notes")}
                        >
                          <FontAwesomeIcon
                            color="#de924b"
                            icon={faPenToSquare}
                          />
                        </Button>
                      </div>
                      <div>
                        <Button
                          bg={
                            item.newPrivateMessages > 0 ? "#fadede" : "gray.100"
                          }
                          p={"1px 21px 1px 15px"}
                          size={"xs"}
                          color="#05cdff"
                          onClick={() => handleSubClick("chat")}
                          leftIcon={
                            <FontAwesomeIcon color="#4bb2de" icon={faComment} />
                          }
                        >
                          <HStack>
                            <span
                              padding={"0px 5px"}
                              className="small lightgray"
                            >
                              {item.allPrivateMessages}
                            </span>
                            {item.newPrivateMessages > 0 && (
                              <text padding={"0px 5px"} className="small red">
                                {item.newPrivateMessages}
                              </text>
                            )}
                          </HStack>
                        </Button>
                      </div>
                      <div>
                        <Button
                          bg={
                            item.newPrivateMessages > 0 ? "#fadede" : "gray.100"
                          }
                          p={"1px 21px 1px 15px"}
                          size={"xs"}
                          color="#05cdff"
                          onClick={() => handleSubClick("chats")}
                          leftIcon={
                            <FontAwesomeIcon
                              color="#4bb2de"
                              icon={faComments}
                            />
                          }
                        >
                          <HStack>
                            <span
                              padding={"0px 5px"}
                              className="small lightgray"
                            >
                              {item.allPrivateMessages}
                            </span>
                            {item.newPrivateMessages > 0 && (
                              <text padding={"0px 5px"} className="small red">
                                {item.newPrivateMessages}
                              </text>
                            )}
                          </HStack>
                        </Button>
                      </div>
                      <div>
                        <Button size={"xs"} color={"blue.300"}>
                          <FontAwesomeIcon icon={faUsers} />
                        </Button>
                      </div>
                    </>
                  )}
                </HStack>
              </div>
            </div>
            <Button
              borderRadius={5}
              className="nomargin showsubsbutton"
              onClick={() => {
                if (subtasks?.length > 0) {
                  setSubtasks([]);
                } else {
                  getSubtasksIds();
                }
                setSubtasksRow(!subtasksRow);
              }}
              size={"sm"}
              margin={"2px 0px 2px 0px"}
            >
              {subtasksRow ? (
                <FontAwesomeIcon color="#a5e0fa" icon={faAnglesUp} />
              ) : (
                <FontAwesomeIcon color="#4bb2de" icon={faAnglesDown} />
              )}
            </Button>
          </HStack>
        </td>
        <CalendarRow
          baseDate={baseDate}
          target={"task"}
          item={item}
          title={title}
          setTitle={setTitle}
          setTaskDate={setTaskDate}
          isOpen={isOpen}
          onOpen={onOpen}
          setCid={setCid}
          onClose={onClose}
        />
      </tr>
      {subtasksRow && subtasks?.length <= 0 && (
        <tr>
          <td className="calendarbutton">
            <HStack w={"100%"} borderRadius={"0"}>
              <div className="addsubcalempty">
                <Button
                  borderRadius={5}
                  w={"100%"}
                  className="nomargin"
                  borderColor={"white"}
                  size={"xs"}
                  margin={"0px 0px 2px 0px"}
                >
                  <FontAwesomeIcon size="lg" color="#5dc2f5" icon={faPlus} />
                </Button>
              </div>
            </HStack>
          </td>
          <td colspan={10} height={"40px"}>
            {subtasks?.length <= 0 && (
              <p className={"small gray"}>nie ma jeszcze podzadań...</p>
            )}
          </td>
        </tr>
      )}
      {subtasks.length > 0 &&
        subtasks.map((item, index) => (
          <tr>
            <td className="calendarbutton">
              <HStack w={"100%"} p={"0px 1px 3px 0px"} borderRadius={"0"}>
                <div
                  className="container cointeinersub hand"
                  alignContent={"right"}
                  alignItems={"right"}
                >
                  <Button
                    className="nomargin"
                    bg={"blue.200"}
                    borderColor={"white"}
                    size={"xs"}
                  >
                    <FontAwesomeIcon color="#ffffff" icon={faCaretRight} />
                  </Button>
                  <div class="overlay overlaysub">
                    <HStack className="taskpanel" paddingBottom={"5px"}>
                      <Button
                        marginTop={"5px"}
                        borderRadius={5}
                        w={"100%"}
                        size={"xs"}
                        onClick={() => {
                          dispatch(setModalMode("edit"));
                          openModal();
                        }}
                      >
                        <FontAwesomeIcon color="#aaaaaa" icon={faGear} />
                      </Button>
                      <div className="donetaskwraper">
                        <DoneTaskComponent chat={item} mode={"small"} />
                      </div>

                      <div className="">
                        <Button
                          // bg={"#d5e9f0"}
                          size={"xs"}
                          color="#05cdff"
                          // onClick={() => handleSubClick("notes")}
                        >
                          <FontAwesomeIcon
                            color="#de924b"
                            icon={faPenToSquare}
                          />
                        </Button>
                      </div>
                      <div>
                        <Button
                          bg={
                            item.newPrivateMessages > 0 ? "#fadede" : "gray.100"
                          }
                          p={"1px 21px 1px 15px"}
                          size={"xs"}
                          color="#05cdff"
                          // onClick={() => handleSubClick("chat")}
                          leftIcon={
                            <FontAwesomeIcon color="#4bb2de" icon={faComment} />
                          }
                        >
                          <HStack>
                            <span
                              padding={"0px 5px"}
                              className="small lightgray"
                            >
                              {item.allPrivateMessages}
                            </span>
                            {item.newPrivateMessages > 0 && (
                              <text padding={"0px 5px"} className="small red">
                                {item.newPrivateMessages}
                              </text>
                            )}
                          </HStack>
                        </Button>
                      </div>
                      <div>
                        <Button
                          bg={
                            item.newPrivateMessages > 0 ? "#fadede" : "gray.100"
                          }
                          p={"1px 21px 1px 15px"}
                          size={"xs"}
                          color="#05cdff"
                          // onClick={() => handleSubClick("chat")}
                          leftIcon={
                            <FontAwesomeIcon
                              color="#4bb2de"
                              icon={faComments}
                            />
                          }
                        >
                          <HStack>
                            <span
                              padding={"0px 5px"}
                              className="small lightgray"
                            >
                              {item.allPrivateMessages}
                            </span>
                            {item.newPrivateMessages > 0 && (
                              <text padding={"0px 5px"} className="small red">
                                {item.newPrivateMessages}
                              </text>
                            )}
                          </HStack>
                        </Button>
                      </div>
                      <div>
                        <Button size={"xs"} color={"blue.300"}>
                          <FontAwesomeIcon icon={faUsers} />
                        </Button>
                      </div>
                    </HStack>
                  </div>
                </div>
                {index === 0 && (
                  <Button
                    borderRadius={5}
                    className="nomargin addsubcal"
                    borderColor={"white"}
                    size={"xs"}
                    margin={"2px 0px 2px 0px"}
                  >
                    <FontAwesomeIcon size="lg" color="#5dc2f5" icon={faPlus} />
                  </Button>
                )}
              </HStack>
            </td>
            <CalendarRow
              baseDate={baseDate}
              target={"subtasks"}
              item={item}
              title={title}
              setTitle={setTitle}
              setTaskDate={setTaskDate}
              isOpen={isOpen}
              onOpen={onOpen}
              setCid={setCid}
              onClose={onClose}
            />
          </tr>
        ))}
      {subtasks.length > 0 && (
        <tr>
          <td height={30} colSpan={10}></td>
        </tr>
      )}
    </>
  );
};

export default CalendarRows;
