import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  Toast,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { mainPath } from "../config/AxiosConfig";
import React, { useEffect, useState } from "react";
import { getSender, getSenderFull } from "../config/ChatLogics";
import { ContextScope } from "../Context/ContextProvider";
import ProfileModal from "./miscellaneous/ProfileModal";
import UpdateGroupChatModal from "./miscellaneous/UpdateGroupChatModal";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import ScrollableChat from "./ScrollableChat";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setFetchMessagesAgain } from "./slices/fetchMessagesAgainSlice";

var selectedChatCompare;

const SingleChat = ({ user, socket }) => {
  console.log("SingleChat");
  console.log(socket);
  console.log("SingleChat");
  const toast = useToast();
  // const user = useSelector((state) => state.userInfo.userInfo);
  const { selectedChat, setSelectedChat } = ContextScope();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setnewMessage] = useState("");
  const dispatch = useDispatch();

  const { notification, setNotification } = ContextScope();
  const fetchMessagesAgain = useSelector(
    (state) => state.fetchMessagesAgain.fetchMessagesAgain
  );

  const fetMessages = async () => {
    if (!selectedChat) return;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios
        .get(`${mainPath}/api/message/${selectedChat._id}`, config)
        .then((res) => {
          setMessages(res.data);
          setLoading(false);
        });

      socket?.emit("join chat", selectedChat._id);
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetMessages();
    selectedChatCompare = selectedChat;
  }, [selectedChat]);

  useEffect(() => {
    socket?.on("message recieved", (newMessageRecieved) => {
      // console.log(newMessageRecieved);
      // console.log(`notification`);
      if (
        !selectedChatCompare || // if chat is not selected or doesn't match current chat
        selectedChatCompare._id !== newMessageRecieved.chat._id
      ) {
        if (!notification.includes(newMessageRecieved)) {
          setNotification([newMessageRecieved, ...notification]);
          dispatch(setFetchMessagesAgain(!fetchMessagesAgain));
        }
      } else {
        setMessages([...messages, newMessageRecieved]);
      }
    });
  });

  const sendMessage = async (event) => {
    if (event.key === "Enter" && newMessage) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };
        setnewMessage("");
        const { data } = await axios.post(
          `${mainPath}/api/message`,
          {
            content: newMessage,
            chatId: selectedChat._id,
          },
          config
        );

        socket?.emit("new message", data);

        setMessages([...messages, data]);
      } catch (error) {
        toast({
          title: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      }
    }
  };
  const sendIt = async (event) => {
    if (newMessage) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };
        setnewMessage("");
        const { data } = await axios.post(
          `${mainPath}/api/message`,
          {
            content: newMessage,
            chatId: selectedChat._id,
          },
          config
        );
        // console.log(data);
        socket?.emit("new message", data);
        setMessages([...messages, data]);
      } catch (error) {
        toast({
          title: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      }
    }
  };

  const typingHandler = (e) => {
    setnewMessage(e.target.value);
  };

  return (
    <>
      {selectedChat ? (
        <>
          <Text
            fontSize={{ base: "28px", md: "30px" }}
            pb={3}
            px={2}
            w="100%"
            fontFamily="Work sans"
            display="flex"
            justifyContent={{ base: "space-between" }}
            alignItems="center"
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              icon={<ArrowBackIcon />}
              onClick={() => setSelectedChat("")}
            />
            {!selectedChat.isGroupChat ? (
              <>
                {getSender(user, selectedChat.users)}
                <ProfileModal user={getSenderFull(user, selectedChat.users)} />
              </>
            ) : (
              <></>
            )}
          </Text>
          <Box
            display="flex"
            flexDir="column"
            justifyContent="flex-end"
            w="100%"
            h="100%"
            p={"0px 10px 0px 5px"}
            backgroundColor={"white"}
            borderRadius="lg"
            overflowY="hidden"
            bg={"#fafafa"}
          >
            {loading ? (
              <Box h={"100%"} padding="6">
                <SkeletonCircle size="10" />
                <SkeletonText
                  mt="4"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="2"
                />
              </Box>
            ) : (
              <>
                {/* <div className="messages"> */}
                {/* {// console.log(messages)}  */}
                <ScrollableChat className="messages" messages={messages} />
                {/* </div> */}
              </>
            )}
          </Box>
          <FormControl onKeyDown={sendMessage} isRequired mt={3}>
            <InputGroup>
              <Input
                variant="filled"
                bg="#eeeeee"
                placeholder="Wpisz wiadomość..."
                onChange={typingHandler}
                value={newMessage}
              />
              <InputRightElement onClick={sendIt}>
                <FontAwesomeIcon
                  className="hand"
                  color="#05cdff"
                  icon={faPaperPlane}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </>
      ) : (
        <Box>
          <Text>Wybierz osobę, do której chcesz napisać...</Text>
        </Box>
      )}
    </>
  );
};

export default SingleChat;
