import { Avatar, Spinner, Tooltip } from "@chakra-ui/react";
import React, { useEffect } from "react";
import ScrollableFeed from "react-scrollable-feed";
import { useSelector } from "react-redux";
import SingleNote from "./SingleNote";

const ScrollableNote = ({ notes }) => {
  const user = useSelector((state) => state.userInfo.userInfo);
  let content = notes.map((note) => <SingleNote note={note} user={user} />);
  return (
    <>
      <ScrollableFeed>{notes ? <>{content}</> : <Spinner />}</ScrollableFeed>
    </>
  );
};

export default ScrollableNote;
