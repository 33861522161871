import {
  Box,
  CircularProgress,
  VStack,
  useToast,
  Button,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Progress,
  Td,
  Tr,
} from "@chakra-ui/react";
import alarm from "../../images/alarm.png";
import alarmon from "../../images/alarmon.png";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import alarmwhite from "../../images/alarmwhite.png";
import alarmonevent from "../../images/alarmonevent.png";
import alarmonevent2 from "../../images/alarmonevent2.png";
import alarmonevent3 from "../../images/alarmonevent3.png";
import event from "../../images/event.png";
import event2 from "../../images/event2.png";
import event3 from "../../images/event3.png";
import orange from "../../images/orange.png";
import red from "../../images/red.png";
import gray from "../../images/gray.png";
import green from "../../images/green.png";
import blue from "../../images/blue.png";
import { format } from "date-fns";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setModalMode } from "../slices/modalModeSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";

const CalendarRow = ({
  target,
  item,
  setCid,
  isOpen,
  onClose,
  onOpen,
  title,
  setTitle,
  setTaskDate,
}) => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const [loading, setLoading] = useState(false);
  const selectedDay = useSelector((state) => state.selectedDay.selectedDay);
  const toast = useToast();
  const [subtasks, setSubtasks] = useState(false);
  const [done, setDone] = useState(item.done);
  const [rowTitle, setRowTitle] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [dateTo, setDateTo] = useState();
  const [baseDate, setBaseDate] = useState(new Date());
  const [daysCount, setDaysCount] = useState(30);
  const [alarmDate, setAlarmDate] = useState([]);
  const [events, setEvents] = useState([]);
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const [myArray, setMyArray] = useState([]);
  const [start, setStart] = useState();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo.userInfo);

  const getTask = async () => {
    // if (target === "task") {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/single/${item._id}`,
          {
            chatId: item._id,
            userId: user._id,
          },
          config
        )
        .then((res) => {
          setLoading(false);
          setCreatedAt(res.data.dateFrom);
          setAlarmDate(res.data.alarms);
          setEvents(res.data.events);
          setDateTo(new Date(res.data.dateTo));
          setTitle(res.data.chatName);
          setRowTitle(res.data.chatName);
          setStart(new Date(res.data.dateFrom));
          setCid(res.data._id);
          setDone(res.data.done);

          // console.log(`res.data.done`);
          // console.log(res.data);
          // console.log(`res.data.done`);
          //   setMyArray(content({ start: res.data.createdAt }));
        });
    } catch (error) {
      // //console.log(error);
      setLoading(false);
    }
    // } else if (target === "subtasks") {
    //   try {
    //     const config = {
    //       headers: {
    //         Authorization: `Bearer ${user.token}`,
    //       },
    //     };
    //     setLoading(true);
    //     const { data } = await axios
    //       .post(
    //         `${mainPath}/api/chat/calendarsubtasks/${item._id}`,
    //         {
    //           chatId: item._id,
    //           userId: user._id,
    //         },
    //         config,
    //       )
    //       .then((res) => {
    //         //console.log(res.data);
    //         setLoading(false);
    //         setCreatedAt(res.data.dateFrom);
    //         setAlarmDate(res.data.alarms);
    //         setDateTo(new Date(res.data.dateTo));
    //         setTitle(res.data.chatName);
    //         setRowTitle(res.data.chatName);
    //         setStart(new Date(res.data.dateFrom));
    //         setCid(res.data._id);
    //         //   setMyArray(content({ start: res.data.createdAt }));
    //       });
    //   } catch (error) {
    //     // //console.log(error);
    //     setLoading(false);
    //   }
    // }
  };
  useEffect(() => {
    if (user._id) {
      getTask();
    }
  }, [user]);
  useEffect(() => {
    if (item._id === selectedTask || selectedTask === "all") {
      getTask();
      setBaseDate(new Date(format(selectedDay.min, "yyyy/MM/dd")));
    }
  }, [fetchAgain]);
  useEffect(() => {
    if (start) {
      // //console.log(`start: ${start}`);
      setMyArray(
        content({ startDate: start, alarmDate: alarmDate, eventsDate: events })
      );
    }
  }, [start]);
  useEffect(() => {
    console.log(selectedDay);
    setBaseDate(new Date(format(selectedDay.min, "yyyy/MM/dd")));
    setDaysCount(selectedDay.daysCount);
  }, [selectedDay]);
  //   let start = new Date("2024/07/05");
  let content = ({ startDate, alarmDate, eventsDate }) => {
    let tds = [];
    // //console.log(startDate);
    let currentTaskDate = new Date(format(selectedDay.min, "yyyy/MM/dd"));
    let daysLeft = Math.floor((dateTo - Date.now()) / 86400000);
    for (let i = 0; i < daysCount; i++) {
      baseDate.setDate(baseDate.getDate() + 1);
      // //console.log(
      //   `baseDate: ${baseDate};  start: ${startDate};  currentTaskDate: ${currentTaskDate}`,
      // );
      if (baseDate >= startDate) {
        currentTaskDate.setDate(currentTaskDate.getDate() + 1);
      }
      // const isAlarm
      const isAlarm = alarmDate?.map((d) => {
        const nd = new Date(`${d.year}-${d.month}-${d.day}`);
        if (nd.toDateString() === baseDate.toDateString()) {
          return nd;
        }
      });
      const isEvent = eventsDate?.map((e) => {
        const ne = new Date(`${e.year}-${e.month}-${e.day}`);
        if (ne.toDateString() === baseDate.toDateString()) {
          return ne;
        }
      });
      console.log(`ISEVENT: ${isEvent.length}`);
      const tddate = format(baseDate, "yyyy-MM-dd").toString();
      tds.push(
        <td
          onClick={() => {
            dispatch(setSelectedTask(item._id));
            dispatch(setModalMode("calendardate"));
            setTitle(item.chatName);
            setTaskDate(tddate);
            setCid(item._id);
            onOpen();
          }}
          className={
            format(baseDate, "yyyy/MM/dd") === format(new Date(), "yyyy/MM/dd")
              ? "todaytd"
              : "calendartd"
          }
          height={"100%"}
          padding={0}
          margin={0}
          style={
            baseDate >= startDate && baseDate <= dateTo
              ? done === true
                ? {
                    backgroundRadius: `2px`,
                    borderRadius: `2px`,
                    backgroundColor: `rgba(117, 158, 3, 0.2)`,
                    backgroundImage: isAlarm.find(
                      (d) => baseDate.getDate() === d?.getDate()
                    )
                      ? isEvent.find((e) => baseDate.getDate() === e?.getDate())
                        ? isEvent.length === 1
                          ? `url(${alarmonevent})`
                          : isEvent.length === 2
                          ? `url(${alarmonevent2})`
                          : isEvent.length === 3
                          ? `url(${alarmonevent3})`
                          : `url(${alarmonevent3})`
                        : `url(${alarmon})`
                      : isEvent.find((e) => baseDate.getDate() === e?.getDate())
                      ? isEvent.filter(
                          (e) => baseDate.getDate() === e?.getDate()
                        ).length === 1
                        ? `url(${event})`
                        : isEvent.filter(
                            (e) => baseDate.getDate() === e?.getDate()
                          ).length === 2
                        ? `url(${event2})`
                        : `url(${event3})`
                      : ``,
                    backgroundSize: `14px`,
                    backgroundRepeat: `no-repeat`,
                    backgroundPosition: `3px 3px`,
                  }
                : daysLeft <= 6
                ? daysLeft <= 1
                  ? {
                      backgroundRadius: `2px`,
                      borderRadius: `2px`,
                      backgroundColor: `rgba(255, 0, 0, 0.2)`,
                      backgroundImage: isAlarm.find(
                        (d) => baseDate.getDate() === d?.getDate()
                      )
                        ? isEvent.find(
                            (e) => baseDate.getDate() === e?.getDate()
                          )
                          ? isEvent.length === 1
                            ? `url(${alarmonevent})`
                            : isEvent.length === 2
                            ? `url(${alarmonevent2})`
                            : isEvent.length === 3
                            ? `url(${alarmonevent3})`
                            : `url(${alarmonevent3})`
                          : `url(${alarmon})`
                        : isEvent.find(
                            (e) => baseDate.getDate() === e?.getDate()
                          )
                        ? isEvent.filter(
                            (e) => baseDate.getDate() === e?.getDate()
                          ).length === 1
                          ? `url(${event})`
                          : isEvent.filter(
                              (e) => baseDate.getDate() === e?.getDate()
                            ).length === 2
                          ? `url(${event2})`
                          : `url(${event3})`
                        : ``,
                      backgroundSize: `14px`,
                      backgroundRepeat: `no-repeat`,
                      backgroundPosition: `3px 3px`,
                    }
                  : {
                      backgroundRadius: `2px`,
                      borderRadius: `2px`,
                      backgroundColor: `rgba(212, 99, 0, 0.2)`,
                      backgroundImage: isAlarm.find(
                        (d) => baseDate.getDate() === d?.getDate()
                      )
                        ? isEvent.find(
                            (e) => baseDate.getDate() === e?.getDate()
                          )
                          ? isEvent.length === 1
                            ? `url(${alarmonevent})`
                            : isEvent.length === 2
                            ? `url(${alarmonevent2})`
                            : isEvent.length === 3
                            ? `url(${alarmonevent3})`
                            : `url(${alarmonevent3})`
                          : `url(${alarmon})`
                        : isEvent.find(
                            (e) => baseDate.getDate() === e?.getDate()
                          )
                        ? isEvent.filter(
                            (e) => baseDate.getDate() === e?.getDate()
                          ).length === 1
                          ? `url(${event})`
                          : isEvent.filter(
                              (e) => baseDate.getDate() === e?.getDate()
                            ).length === 2
                          ? `url(${event2})`
                          : `url(${event3})`
                        : ``,
                      backgroundSize: `14px`,
                      backgroundRepeat: `no-repeat`,
                      backgroundPosition: `3px 3px`,
                    }
                : {
                    backgroundRadius: `2px`,
                    borderRadius: `2px`,
                    backgroundColor: `rgb(0, 123, 255, 0.2)`,
                    backgroundImage: isAlarm.find(
                      (d) => baseDate.getDate() === d?.getDate()
                    )
                      ? isEvent.find((e) => baseDate.getDate() === e?.getDate())
                        ? isEvent.length === 1
                          ? `url(${alarmonevent})`
                          : isEvent.length === 2
                          ? `url(${alarmonevent2})`
                          : isEvent.length === 3
                          ? `url(${alarmonevent3})`
                          : `url(${alarmonevent3})`
                        : `url(${alarmon})`
                      : isEvent.find((e) => baseDate.getDate() === e?.getDate())
                      ? isEvent.filter(
                          (e) => baseDate.getDate() === e?.getDate()
                        ).length === 1
                        ? `url(${event})`
                        : isEvent.filter(
                            (e) => baseDate.getDate() === e?.getDate()
                          ).length === 2
                        ? `url(${event2})`
                        : `url(${event3})`
                      : ``,
                    backgroundSize: `14px`,
                    backgroundRepeat: `no-repeat`,
                    backgroundPosition: `3px 3px`,
                  }
              : baseDate?.getDate() === null
              ? {
                  backgroundRadius: `2px`,
                  borderRadius: `2px`,
                  backgroundColor: `rgba(117, 158, 3, 0.2)`,
                  backgroundImage: isAlarm.find(
                    (d) => baseDate.getDate() === d?.getDate()
                  )
                    ? isEvent.find((e) => baseDate.getDate() === e?.getDate())
                      ? isEvent.length === 1
                        ? `url(${alarmonevent})`
                        : isEvent.length === 2
                        ? `url(${alarmonevent2})`
                        : isEvent.length === 3
                        ? `url(${alarmonevent3})`
                        : `url(${alarmonevent3})`
                      : `url(${alarmon})`
                    : isEvent.find((e) => baseDate.getDate() === e?.getDate())
                    ? isEvent.filter((e) => baseDate.getDate() === e?.getDate())
                        .length === 1
                      ? `url(${event})`
                      : isEvent.filter(
                          (e) => baseDate.getDate() === e?.getDate()
                        ).length === 2
                      ? `url(${event2})`
                      : `url(${event3})`
                    : ``,
                  backgroundSize: `14px`,
                  backgroundRepeat: `no-repeat`,
                  backgroundPosition: `3px 3px`,
                }
              : {
                  backgroundRadius: `2px`,
                  borderRadius: `2px`,
                  backgroundColor: `rgb(0, 0, 0, 0.02)`,
                  backgroundImage: isAlarm.find(
                    (d) => baseDate.getDate() === d?.getDate()
                  )
                    ? isEvent.find((e) => baseDate.getDate() === e?.getDate())
                      ? isEvent.length === 1
                        ? `url(${alarmonevent})`
                        : isEvent.length === 2
                        ? `url(${alarmonevent2})`
                        : isEvent.length === 3
                        ? `url(${alarmonevent3})`
                        : `url(${alarmonevent3})`
                      : `url(${alarmon})`
                    : isEvent.find((e) => baseDate.getDate() === e?.getDate())
                    ? isEvent.filter((e) => baseDate.getDate() === e?.getDate())
                        .length === 1
                      ? `url(${event})`
                      : isEvent.filter(
                          (e) => baseDate.getDate() === e?.getDate()
                        ).length === 2
                      ? `url(${event2})`
                      : `url(${event3})`
                    : ``,
                  backgroundSize: `14px`,
                  backgroundRepeat: `no-repeat`,
                  backgroundPosition: `3px 3px`,
                }
          }
          key={i}
        >
          <div padding={0} margin={0} height={"100%"}>
            &nbsp;
            {/* <VStack>
              <p className="small">{baseDate.toString()}</p>
              <p className="small">{startDate.toString()}</p>
              <p className="small">{dateTo.toString()}</p>
            </VStack> */}
          </div>
          {/* <Popover className="calendarpopup">
            <PopoverTrigger>
              <div padding={0} margin={0} className="boxbutton" height={"100%"}>
                &nbsp;
              </div>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader
                bg={"#fafafa"}
                borderRadius={"5px 5px 0px 0px"}
                padding={"30px 13px 15px 13px"}
                height={"auto"}
              >
                <PopoverCloseButton />
                <p className="small ">{title}</p>
              </PopoverHeader>
              <PopoverBody
                paddingBottom={"20px"}
                bg={"#ffffff"}
                borderRadius={"0px 0px 5px 5px"}
              ></PopoverBody>
            </PopoverContent>
          </Popover> */}
          {/* <div>{baseDate.getFullYear()}</div> */}
        </td>
      );
    }
    return tds;
  };
  return (
    <>
      {loading ? (
        <td
          height={"40px"}
          colSpan={daysCount}
          width={"100%"}
          alignContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
          paddingLeft="10px"
          textAlign={"center"}
          className="calendarprogress"
        >
          <Progress height={"1px"} w={"100%"} size="xs" isIndeterminate />
        </td>
      ) : (
        <>
          <td className="calendartitle">
            <p>
              <span className={target === "task" ? "strong" : ""}>
                {rowTitle}{" "}
              </span>
              <span className="small gray">
                ( {new Date(item.dateFrom).toLocaleDateString()} -
                {new Date(dateTo).toLocaleDateString()} )
              </span>
            </p>
          </td>
          {myArray.map((item) => item)}
        </>
      )}
    </>
  );
};

export default CalendarRow;
