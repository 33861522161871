import { Button } from "@chakra-ui/button";
import React, { useEffect, useState } from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { setUserInfo } from "../slices/userInfoSlice";

const Login = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const handleClick = () => {
    setShow(!show);
  };
  const user = useSelector((state) => state.userInfo.userInfo);
  useEffect(() => {
    if (user?.email) {
      // console.log("user");
      // // console.log(user);
      // console.log("user");
      // history.push("/chats");
    }
  }, [user]);

  const submitHandler = async () => {
    setLoading(true);
    if (!email || !password) {
      toast({
        title: "Please fill all the fields...",
        status: "warning",
        duration: "3000",
        isClosable: true,
      });
      return;
    }
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${mainPath}/api/user/login`,
        {
          email,
          password,
        },
        config,
      );
      localStorage.setItem("userInfo", JSON.stringify(data));
      dispatch(setFetchAgain(true));
      dispatch(setUserInfo(data));
      setLoading(false);
      history.push("/chats");
    } catch (error) {
      if (error) {
        // // console.log(error);
        toast({
          title: "Something went wrong.",
          description: error?.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      setLoading(false);
    }
  };

  return (
    <VStack spacing="5px">
      <FormControl id="email" isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          placeholder="wprowadź email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl id="password" isRequired>
        <FormLabel>Hasło</FormLabel>
        <InputGroup size="md">
          <Input
            placeholder="wprowadź hasło"
            type={show ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputRightElement width="4,5rem">
            <Button
              bgColor="transparent"
              h="1,75rem"
              size="sm"
              p={3}
              onClick={handleClick}
            >
              {show ? "Ukryj" : "Pokaż"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <Button
        marginTop={"20px"}
        colorScheme="blue"
        width="100%"
        onClick={submitHandler}
        isLoading={loading}
      >
        Login
      </Button>
    </VStack>
  );
};

export default Login;
